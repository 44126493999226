





















import { Component, Vue, Prop } from 'vue-property-decorator';
import Masthead from './Common/Masthead.vue';

@Component({
  components: {
    Masthead,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  private readonly mini!: boolean;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop()
  private readonly image!: string;

  @Prop()
  private readonly darken!: boolean;

  @Prop()
  private readonly height!: string;
}
