import { ListingFilter } from 'client-website-ts-library/filters/ListingFilter';
import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/properties',
    name: 'For Sale',
    component: () => import('../views/Properties.vue'),
  },
  {
    path: '/businesses-for-sale',
    name: 'Businesses For Sale',
    component: () => import('../views/BusinessesForSale.vue'),
  },
  {
    path: '/properties/for-sale',
    name: 'For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/buyer-enquiry',
    name: 'Buyer Enquiry',
    component: () => import('../views/BuyerEnquiry.vue'),
  },
  {
    path: '/market-updates',
    name: 'Market Updates',
    component: () => import('../views/MarketUpdates.vue'),
  },
  {
    path: '/properties/for-lease',
    name: 'For Lease',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/lease-appraisal',
    name: 'Lease Appraisal',
    component: () => import('../views/LeaseAppraisal.vue'),
  },
  {
    path: '/properties/residential-for-sale',
    name: 'Residential Properties For Sale',
    component: () => import('../views/ResidentialPropertiesForSale.vue'),
  },
  {
    path: '/properties/residential-land-for-sale',
    name: 'Residential Land For Sale',
    component: () => import('../views/ResidentialLandForSale.vue'),
  },
  {
    path: '/properties/sold-and-leased',
    name: 'Sold and Leased',
    component: () => import('../views/PropertiesSoldAndLeased.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        const idPart = to.fullPath.substring(1);

        if (/^\d+$/.test(idPart)) {
          API.Listings.Search(new ListingFilter({
            PropertyId: idPart,
            PageSize: 1,
          })).then((result) => {
            if (result.Items.length > 0) {
              next({
                path: `/listings/${result.Items[0].Id}`,
                replace: true,
              });
            } else {
              next();
            }
          }).catch(() => {
            next();
          });
        } else {
          next();
        }
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    if (to.hash) return { selector: to.hash };

    return { x: 0, y: 0 };
  },
});

export default router;
