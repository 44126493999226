





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

import { ItemCyclerSlide, ItemCyclerSlideType, ItemCyclerEmbeddableSlide } from '../../app_code/ItemCycler';
import { CarouselBreakpoint } from '../../app_code/CarouselBreakpoint';

import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';
import ItemCyclerItem from './ItemCyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    ItemCyclerItem,
  },
})
export default class ItemCycler extends Vue {
  @Prop()
  private readonly objects!: ItemCyclerSlide[];

  @Prop()
  private readonly darken!: boolean;

  @Prop({ required: true })
  private readonly items!: number;

  @Prop()
  private readonly autoAdvance!: boolean;

  @Prop({ default: 5000 })
  private readonly autoAdvanceInterval!: number;

  @Prop({ default: 2500 })
  private readonly speed!: number;

  @Prop({ default: true })
  private readonly loop!: boolean;

  @Prop()
  private readonly hideNav!: boolean;

  @Prop()
  private readonly center!: boolean;

  @Prop({ default: () => [] })
  private readonly responsive!: CarouselBreakpoint[];

  private index = 0;

  get videoIndex(): number {
    return this.objects.findIndex((obj) => obj.type === ItemCyclerSlideType.Embeddable && ((obj as ItemCyclerEmbeddableSlide).embeddable.Type === EmbeddedContentType.Youtube || (obj as ItemCyclerEmbeddableSlide).embeddable.Type === EmbeddedContentType.Vimeo));
  }

  get showVideoNav(): boolean {
    const idx = this.videoIndex;

    return idx !== -1 && idx !== this.index;
  }

  navToVideo(): void {
    (this.$refs.cycler as any).goToIndex(this.videoIndex);

    this.$emit('viewVideo');
  }

  handleUpdate(data: any): void {
    this.index = data.index;
  }
}
