




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class RichText extends Vue {
  @Prop({ required: true })
  private readonly content!: string;
}
