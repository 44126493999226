






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ButtonGroup extends Vue {
  @Prop()
  private readonly grow!: boolean;
}
