















































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import RangeSlider from 'client-website-ts-library/components/RangeSlider.vue';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus } from 'client-website-ts-library/types';
import { API } from 'client-website-ts-library/services';
import ToggleButton from './ToggleButton.vue';

@Component({
  components: {
    MultiSelect,
    RangeSlider,
    ToggleButton,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'For Sale' })
  private readonly methodOfSale!: string;

  private mos: string = this.methodOfSale;

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private salePriceRange: number[] = [];

  private leasePriceRange: number[] = [];

  private buildingPriceRange: number[] = [];

  private keywordOptions: MultiSelectOption[] = [];

  private selectedKeywords: MultiSelectOption[] = [];

  private beds = 0;

  private baths = 0;

  private formatCurrency = (val: number): string => `$${val.toLocaleString('en-AU')}`;

  private formatArea = (val: number): string => `${val.toLocaleString('en-AU')}sqm`;

  private isVacantPosession = false;

  private isTenanted = false;

  private isStandalone = false;

  private isStrata = false;

  mounted() {
    this.updateFilter();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale;
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((keywordOptions) => {
        const opts = keywordOptions.map((suburb) => ({ Value: `suburb:${suburb}`, Label: suburb.toUpperCase() }));

        opts.push(...[
          {
            Value: 'categories:8',
            Label: 'Industrial',
          },
          {
            Value: 'categories:12',
            Label: 'Offices',
          },
          {
            Value: 'categories:15',
            Label: 'Retail',
          },
          {
            Value: 'categories:9',
            Label: 'Land Development',
          },
          {
            Value: 'categories:11',
            Label: 'Medical Consulting',
          },
        ]);

        this.keywordOptions = opts;
      });
    }, 2000);
  }

  @Watch('mos')
  @Watch('beds')
  @Watch('baths')
  updateFilter() {
    const opts = this.selectedKeywords.map((s) => {
      const split = s.Value.split(':');

      return {
        Type: split[0],
        Value: split[1],
      };
    });

    switch (this.mos) {
      case 'buy':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
        ];
        break;
      case 'rent':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];
        break;
      default:
        this.filter.Categories = [];
        break;
    }

    this.updateSuburbs();

    this.filter.MinBedrooms = this.beds;
    this.filter.MinBathrooms = this.baths;

    this.filter.PropertyCategories = opts.filter((s) => s.Type === 'categories').map((s) => parseInt(s.Value, 10));
    this.filter.Suburbs = opts.filter((s) => s.Type === 'suburb').map((s) => s.Value);

    const customOpt = opts.find((s) => s.Type === 'search');

    if (customOpt) {
      this.filter.SearchTerm = customOpt.Value;
    } else {
      this.filter.SearchTerm = undefined;
    }

    this.filter.Page = 1;

    this.emitFilter();
  }

  suburbsUpdated(selectedKeywords: MultiSelectOption[]) {
    this.selectedKeywords = selectedKeywords;

    this.updateFilter();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }

  doSearch() {
    const params: Record<string, string> = {};

    if (this.buildingPriceRange.length === 2) {
      params.areaMin = this.buildingPriceRange[0].toString();
      params.areaMax = this.buildingPriceRange[1].toString();
    }

    if (this.selectedKeywords.length) {
      params.keywordOptions = this.selectedKeywords.map((s) => s.Value).join('|');
    }

    if (this.isVacantPosession) params.isVacantPosession = 'true';
    if (this.isTenanted) params.isTenanted = 'true';
    if (this.isStandalone) params.isStandalone = 'true';
    if (this.isStrata) params.isStrata = 'true';

    console.log(this.salePriceRange, this.leasePriceRange);

    switch (this.mos) {
      case 'For Lease':
        // eslint-disable-next-line no-lone-blocks
        {
          if (this.leasePriceRange.length === 2) {
            params.priceMin = this.leasePriceRange[0].toString();
            params.priceMax = this.leasePriceRange[1].toString();
          }

          this.$router.push({
            path: '/properties/for-lease',
            query: params,
          });
        }
        break;
      case 'For Sale':
      default:
        // eslint-disable-next-line no-lone-blocks
        {
          if (this.salePriceRange.length === 2) {
            params.priceMin = this.salePriceRange[0].toString();
            params.priceMax = this.salePriceRange[1].toString();
          }

          this.$router.push({
            path: '/properties/for-sale',
            query: params,
          });
        }
        break;
    }
  }
}
