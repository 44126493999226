import PortalVue from 'portal-vue';

// Load in any polyfills
import 'client-website-ts-library/util/Polyfills';

import {
  API,
  Config,
  Logger,
  LogLevel,
  ServiceManager,
  ServiceType,
  HeadService,
} from 'client-website-ts-library/services';
import { RUMJSEvent, IRUMRecorder } from 'client-website-ts-library/services/RUM';

import Vue from 'vue';

import App from './App.vue';
import router from './router';

// Register any global controls
import './components/global';

// Load in any static config files
import Configuration from './config';

import SiteMetadata from './meta.json';

Vue.use(PortalVue);

// Configure ther logger
Logger.SetLevel(Configuration.Mode === 'local' ? LogLevel.Debug : LogLevel.Warning);

Vue.config.productionTip = Configuration.Mode === 'local';

function mountApp(errored: boolean): void {
  new Vue({
    router,
    render: (h) => h(App, { props: { errored } }),
    mounted() {
      setTimeout(() => {
        const timing = window.performance.getEntriesByType('navigation');

        if (timing.length) {
          ServiceManager.GetAll<IRUMRecorder>(ServiceType.RUMRecorder).forEach((r) => r.RecordPageLoad(timing[0] as PerformanceNavigationTiming));
        }
      }, 100);
    },
  }).$mount('#app');
}

// Bootstrap the API and services
Config.Configure(Configuration).then(() => {
  window.addEventListener('error', (error) => {
    console.log(error);
  });

  ServiceManager.Register(new HeadService({
    meta: SiteMetadata,
  }));

  const rumServices = ServiceManager.GetAll<IRUMRecorder>(ServiceType.RUMRecorder);

  Vue.config.errorHandler = (err, vm, info) => {
    rumServices.forEach((r) => r.RecordJSEvent(RUMJSEvent.FromVueError(err.message, info)));

    console.error(err, info);
  };

  Vue.config.warnHandler = (err, vm, info) => {
    rumServices.forEach((r) => r.RecordJSEvent(RUMJSEvent.FromVueWarn(err, info)));

    console.warn(err, info);
  };

  mountApp(false);

  console.log('%cPRO%cLIST®', 'color:#ed1c24;font-weight: bold;font-size: 20px;', 'color:black;font-weight: bold;font-size: 20px;');
  console.log('%cReal Estate Software', 'color: #000;');
  console.log('https://prolist.net.au');

  rumServices.forEach((r) => r.ReportDeviceMeta());
}).catch((err: any) => {
  Logger.Log(LogLevel.Error, 'Failed to initialise website', err);

  mountApp(true);

  const rumServices = ServiceManager.GetAll<IRUMRecorder>(ServiceType.RUMRecorder);

  rumServices.forEach((r) => r.ReportOutage(Config.Website.Id, err));

  const ping = () => {
    API.Website.Ping().then(() => {
      // window.location.reload();
    }).catch(() => {
      setTimeout(() => {
        ping();
      }, 5 * 1000);
    });
  };

  ping();
});
