





























































































































import { Component, Mixins } from 'vue-property-decorator';

import { ListingFilter } from 'client-website-ts-library/filters';
import {
  ListingStatus,
  ListingCategory,
  CountListingsRequest,
  PropertyCategory,
} from 'client-website-ts-library/types';

import { API } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';

import ListingSearch from '@/components/ListingSearch.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import Listings from '../components/Listings.vue';
import ItemCycler from '../components/Cycler/ItemCycler.vue';

import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';

@Component({
  components: {
    Listings,
    ItemCycler,
    ListingSearch,
    Embeddable,
  },
})
export default class Home extends Mixins(View) {
  private listingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    Categories: [
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: 6,
  });

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({ source: '/assets/images/home/slider/1', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' }),
  ];

  private industrialCount = 0;

  private officeCount = 0;

  private retailCount = 0;

  private developmentCount = 0;

  private medicalCount = 0;

  private miniStorageCount = 0;

  mounted() {
    API.Listings.Bulk([
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.IndustrialWarehouse], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.industrialCount = res.Count;
      }),
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.Offices], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.officeCount = res.Count;
      }),
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.Retail], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.retailCount = res.Count;
      }),
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.LandDevelopment], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.developmentCount = res.Count;
      }),
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.MedicalConsulting], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.medicalCount = res.Count;
      }),
      new CountListingsRequest(new ListingFilter({ PropertyCategories: [PropertyCategory.ShowroomsBulkyGoods], Statuses: [ListingStatus.Current, ListingStatus.UnderContract] }), (res) => {
        this.miniStorageCount = res.Count;
      }),
    ]);
  }
}
