























import { Component, Vue, Prop } from 'vue-property-decorator';
import {
  FirebaseMessageData,
  NewListingMessageData,
  ListingPriceChangeMessageData,
  NewBlogPostMessageData,
  FirebaseMessageDataType,
  NotificationAction,
  NotificationActionType,
} from 'client-website-ts-library/services/ThirdParty/Firebase';
import { API, Logger, LogLevel } from 'client-website-ts-library/services';
import { Listing } from 'client-website-ts-library/types/Listing';

@Component
export default class Notification extends Vue {
  @Prop()
  private readonly notification!: FirebaseMessageData | NewListingMessageData | ListingPriceChangeMessageData | NewBlogPostMessageData;

  private listing: Listing | null = null;

  private ready = false;

  private hideTimeout: number | null = null;

  private actions: NotificationAction[] = [];

  private imageLoaded = false;

  mounted() {
    this.loadData().then(() => {
      this.ready = true;

      if (this.notification.dwell >= 0) {
        this.hideTimeout = setTimeout(() => {
          this.remove();
        }, this.notification.dwell === 0 ? 5000 : this.notification.dwell);
      }
    });
  }

  handleAction(action: NotificationAction) {
    action.Callback();

    Logger.Log(LogLevel.Debug, '[Notification]', 'Action clicked', action, this);
  }

  loadData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      switch (this.notification.type) {
        case FirebaseMessageDataType.NewListing:
          API.Listings.Get((this.notification as NewListingMessageData).listingId).then((listing) => {
            this.listing = listing;

            this.actions.push(new NotificationAction(NotificationActionType.View, 'More Details', () => {
              this.handleClicked();
            }));

            resolve();
          }).catch(reject);
          break;
        default:
          resolve();
          break;
      }
    });
  }

  handleClicked(): void {
    switch (this.notification.type) {
      case FirebaseMessageDataType.NewListing:
        this.$router.push(`/listings/${(this.notification as NewListingMessageData).listingId}`);
        break;
      case FirebaseMessageDataType.ListingPriceChange:
        this.$router.push(`/listings/${(this.notification as ListingPriceChangeMessageData).listingId}`);
        break;
      default:

        break;
    }

    this.remove();
  }

  handleImageLoaded(): void {
    requestAnimationFrame(() => {
      this.imageLoaded = true;
    });
  }

  remove(): void {
    this.ready = false;

    setTimeout(() => {
      this.$emit('removed', this.notification.id);
    }, 500);
  }
}
